<template>
  <span
    v-if="car.state && (car.stage === 'FINISHED' || car.stage === 'REMOVED')"
    :class="{
      [COLORS.green.bg]: state.isSold,
      [COLORS.red.bg]: state.isNotSold,
      [COLORS.orange.bg]:
        state.isUnderReservation && !state.isUnderAuctionReservation,
      [COLORS.darkOrange.bg]: state.isUnderAuctionReservation,
      'text-xs': props.size !== 'big',
      'md:py-4 md:text-2xl': props.size === 'big'
    }"
    class="absolute inset-x-0 bottom-0 z-20 block w-full whitespace-nowrap px-2 py-1 text-center uppercase tracking-wider text-white"
  >
    {{ $t(`shared-car.${car.state.toLowerCase()}`) }}
  </span>
</template>

<script lang="ts" setup>
import type { AuctionCar } from '@autobid/ui/types/Car'
import type { AuctionStateStatic } from '@autobid/ui/types/AuctionState'
import { COLORS } from '@autobid/ui/utils/car/getColor'

type Props = {
  car: AuctionCar
  state: AuctionStateStatic
  size?: 'big' | 'small'
}

const props = defineProps<Props>()
</script>
